<template>
  <validation-observer v-slot="{ invalid }">
    <div class="container-fluid p-0">
      <div
        class="pt-2 pb-2 pl-2 pr-2"
      >
        <b-row>
          <b-col
            cols="12"
          >
            <d-text-field
              v-model="customClient.firstName"
              :rules="validationClient.firstName"
              class-name="background-light-blue-inputs"
              error-text="validation.required"
              place-holder="components.form.client.firstName"
              size="sm"
              autocomplete="autocomplete_off"
            />
          </b-col>
          <b-col
            cols="12"
            class="mt-2"
          >
            <d-text-field
              v-model="customClient.lastName"
              :rules="validationClient.lastName"
              class-name="background-light-blue-inputs"
              error-text="validation.required"
              place-holder="components.form.client.firstName"
              autocomplete="autocomplete_off"
              size="sm"
            />
          </b-col>
          <b-col
            class="mt-2"
            cols="12"
          >
            <d-phone-number
              v-model="customClient.phoneNumber"
              :rules="validationClient.phoneNumber"
              :place-holder="$t('components.form.client.phone')"
              size="sm"
              class-name="background-light-blue-inputs"
              error-text="validation.required"
              autocomplete="autocomplete_off"
            />
          </b-col>
          <b-col
            class="mt-2"
            cols="12">
            <d-text-field
              v-model="customClient.email"
              :rules="validationClient.email"
              class-name="background-light-blue-inputs"
              error-text="validation.custom-client.email"
              place-holder="components.form.client.email-address"
              autocomplete="autocomplete_off"
              size="sm"
            />
          </b-col>
        </b-row>
        <b-row align="center">
          <b-col>
            <div class="mt-2 ml-auto">
              <button
                :disabled="invalid"
                class="btn d-btn-primary btn-sm validate-btn-padding"
                @click="submit"
              >
                {{ $t('general.actions.save') }}
                <i :class="isBusy ? 'fa fa-refresh fa-spin' : ''"></i>
              </button>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
  </validation-observer>
</template>
<script>
import {postClubClient} from "@/api/doinsport/services/client/client.api";
import {SUCCESS} from "@plugins/flash";

export default {
  data: () => ({
    validationClient: require('@/validation/entities/doinsport/CustomClient.json'),
    isBusy: false,
  }),
  props: {
    customClient: {
      type: Object,
      default: () => {
      }
    }
  },
  methods: {
    submit() {
      if (false === this.isBusy) {
        this.isBusy = true;
        const data = {
          club: this.$store.getters["auth/currentClub"]["@id"],
          firstName: this.customClient.firstName,
          lastName: this.customClient.lastName,
          phoneNumber: this.validInput(this.customClient.phoneNumber) ? this.customClient.phoneNumber : null,
          email: this.validInput(this.customClient.email) ? this.customClient.email : null,
        };

        postClubClient(data)
          .then((response) => {
            this.$flash(
              this.$t('components.form.client.modal-custom-client-title'),
              this.$t('components.form.client.modal-custom-client-content'),
              3000,
              SUCCESS)
            ;
            this.$emit('on:form-submit', response.data);
          })
          .finally(() => {
            this.isBusy = false;
          })
        ;
      }
    },
    validInput(input) {
      return (input && input.length > 0);
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@lazy/form/_custom-form.scss";
</style>
